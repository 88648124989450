<template>
  <v-card :loading="loading" outlined>
    <v-toolbar dense elevation="0">
      <v-toolbar-title>Location</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="getData()">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </template>
        <span> Refresh </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="dialog = !dialog">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span> Add </span>
      </v-tooltip>
    </v-toolbar>
    <v-divider></v-divider>
    <v-list>
      <v-list-item two-line v-for="(loc, n) in locationData" :key="n">
        <v-list-item-content>
          <v-list-item-title>{{ loc.location_info }}</v-list-item-title>
          <v-list-item-subtitle>{{
            loc.file_description
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-spacer></v-spacer>
        <v-btn icon @click="edit(loc)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="del(loc.id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>

    <v-dialog v-model="dialog" max-width="500px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-toolbar elevation="0">
            <v-toolbar-title>Add Location</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="dialog = !dialog"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-text-field class="d-none" v-model="id"></v-text-field>
            <v-text-field
              v-model="campaign_no_"
              readonly
              label="Campaign No."
              color="grey"
              disabled
            ></v-text-field>
            <v-text-field
              v-model="addData.location_map"
              :rules="location_mapRules"
              label="Location Maps"
            ></v-text-field>
            <v-textarea
              v-model="addData.location_info"
              :rules="location_infoRules"
              label="Location Info"
              rows="2"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.stop="dialog = !dialog">Cancel</v-btn>
            <v-btn color="primary" @click="save" :disabled="!valid"
              >Sumbit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialog2" max-width="500px">
      <v-form ref="form2" v-model="valid2" lazy-validation>
        <v-card>
          <v-toolbar elevation="0">
            <v-toolbar-title>Add Location</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="dialog2 = !dialog2"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-text-field class="d-none" v-model="id"></v-text-field>
            <v-text-field
              v-model="campaign_no_"
              readonly
              label="Campaign No."
              color="grey"
              disabled
            ></v-text-field>
            <v-text-field
              v-model="editData.location_map"
              :rules="location_mapRules"
              label="Location Maps"
            ></v-text-field>
            <v-textarea
              v-model="editData.location_info"
              :rules="location_infoRules"
              label="Location Info"
              rows="2"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.stop="dialog2 = !dialog2">Cancel</v-btn>
            <v-btn color="primary" @click="update" :disabled="!valid2"
              >Sumbit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  props: {
    no_: String,
    customer_no_: String,
  },
  data: () => ({
    loading: false,
    locationData: [],
    id: 0,
    valid: false,
    valid2: false,
    dialog: false,
    dialog2: false,

    location_mapRules: [(v) => !!v || "Location Map is required"],
    location_infoRules: [(v) => !!v || "Location Map is required"],

    campaign_no_: "",
    addData: {
      location_info: "",
      location_map: "",
    },
    editData: {
      id: null,
      location_info: "",
      location_map: "",
    },
  }),

  methods: {
    getData() {
      this.locationData = [];
      this.loading = true;
      this.campaign_no_ = this.no_;

      var params = {
        campaign_no_: this.campaign_no_,
        customer_no_: this.customer_no_,
      };
      this.$axios
        .get("campaign/data-location", {
          params: params,
        })
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.locationData = resData.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    save() {
      this.$refs.form.validate();

      if (this.$refs.form.validate() == false) return;

      if (!this.valid) return;

      this.loading = true;

      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("campaign_no_", this.campaign_no_);
      formData.append("customer_no_", this.customer_no_);
      formData.append("location_info", this.addData.location_info);
      formData.append("location_map", this.addData.location_map);

      this.$axios
        .post("campaign/save-location", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading = false;
          this.dialog = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.getData();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    del(id) {
      if (confirm("delete?") == false) return;

      this.loading = true;

      let formData = new FormData();
      formData.append("id", id);

      this.$axios
        .post("campaign/delete-location", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.getData();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    edit(data) {
      this.editData.id = data.id;
      this.editData.location_info = data.location_info;
      this.editData.location_map = data.location_map;
      this.dialog2 = true;
    },
    update() {
      this.$refs.form2.validate();

      if (this.$refs.form2.validate() == false) return;

      if (!this.valid2) return;

      this.loading = true;

      let formData = new FormData();
      formData.append("id", this.editData.id);
      formData.append("campaign_no_", this.campaign_no_);
      formData.append("customer_no_", this.customer_no_);
      formData.append("location_info", this.editData.location_info);
      formData.append("location_map", this.editData.location_map);

      this.$axios
        .post("campaign/update-location", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading = false;
          this.dialog2 = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.getData();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false;
          this.dialog2 = false;
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
<template>
  <div>
    <v-card :loading="loading" outlined>
      <v-toolbar dense elevation="0">
        <v-toolbar-title>Attachments</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="getData()">
              <v-icon>mdi-sync</v-icon>
            </v-btn>
          </template>
          <span> Refresh </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="$refs.refAdd.showDialog(campaign_no_, customer_no_)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span> Add </span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>

      <v-list>
        <v-list-item two-line v-for="(file, n) in files" :key="n">
          <v-list-item-content>
            <v-list-item-title>{{ file.file_caption }}</v-list-item-title>
            <v-list-item-subtitle>{{ file.file_description }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <v-btn icon link @click="goToExternalURL((file.file_src))">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn icon @click="$refs.refEdit.showDialog(file)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="del(file.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-card>

    <campaign-attachment-add ref="refAdd" @callback="getData()"></campaign-attachment-add>
    <campaign-attachment-edit ref="refEdit" @callback="getData()"></campaign-attachment-edit>
  </div>
</template>

<script>
import CampaignAttachmentAdd from './CampaignAttachmentAdd.vue';
import CampaignAttachmentEdit from './CampaignAttachmentEdit.vue';
export default {
  components: { CampaignAttachmentAdd, CampaignAttachmentEdit },
  props: {
    no_: String,
    customer_no_: String,
  },
  data: () => ({
    loading: false,
    valid: null,
    files: [],
    dialog: false,
    dialog2: false,
    id: 0,
    campaign_no_: null,
    file_src: null,
    file_caption: null,
    file_description: null,

    file_srcRules: [(v) => !!v || "Image is required"],
    file_captionRules: [(v) => !!v || "Caption is required"],
  }),

  methods: {
    getData() {
      this.files = [];
      this.loading = true;
      this.campaign_no_ = this.no_;

      var params = {
        campaign_no_: this.campaign_no_,
        customer_no_: this.customer_no_,
      };
      this.$axios
        .get("campaign/data-attachments", {
          params: params,
        })
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.files = resData.data.files;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    del(id) {
      if (confirm('delete?') == false) return;

      this.loading = true

      let formData = new FormData();
      formData.append("id", id);

      this.$axios
        .post("campaign/delete-attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading = false
          var resData = res.data;
          if (resData.status == "success") {
            this.getData();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
<template>
  <div>
    <v-card flat :loading="loading" outlined>
      <v-toolbar dense elevation="0" class="mb-4">
        <v-toolbar-title>Project Progress</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line small></v-text-field>

        <v-btn icon @click="getData()" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>

        <v-btn icon @click="addNew()" title="Add New">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>

      <div class="px-2" v-if="isMobile">
        <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
      </div>

      <v-data-table :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" dense class="mb-3 my-2">
        <template v-slot:[`item.id`]="{ item }" v-if="!isMobile">
          <div class="d-flex">
            <v-btn icon text size="8" @click="editData(item)">
              <v-icon size="16">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon text size="8" @click="deleteData(item.id)">
              <v-icon size="16">mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.title`]="{ item }" v-if="!isMobile">
          <a href="javascript:;" @click="editData(item)">{{ item.title }}</a>
        </template>
        <template v-slot:[`item.published`]="{ item }" v-if="!isMobile">
          <span v-if="item.published">Y</span>
          <span v-else>N</span>
        </template>
        <template v-slot:[`item`]="{ item }" v-if="isMobile">
          <v-card elevation="4" class="ma-1" outlined>
            <v-card-title class="text-h5 mb-1 pb-1">item.title{{ item.title }}</v-card-title>
            <v-card-subtitle class="my-0 py-0">
              {{ item.document_date }}<br />
              {{ item.campaign_description }}
            </v-card-subtitle>
            <v-card-actions class="d-flex align-center flex-row mx-2">
              <span class="text--disabled" style="font-size: 12px">{{
                item.document_date
                }}</span>
              <v-spacer></v-spacer>
              <v-btn outlined rounded color="primary" @click="editData(item)">Detail</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-data-table>
    </v-card>


    <ProjectProgressAdd ref="formAdd" :campaign_no_="this.campaign_no_" :customer_no_="this.customer_no_" @callback="addCallback"></ProjectProgressAdd>

    <ProjectProgressEdit ref="formEdit" :campaign_no_="this.campaign_no_" :customer_no_="this.customer_no_" :selected_id="selectedId" @callback="editCallback"></ProjectProgressEdit>
  </div>
</template>

<script>
import ProjectProgressAdd from './ProjectProgressAdd.vue'
import ProjectProgressEdit from './ProjectProgressEdit.vue'

export default {
  components: {
    ProjectProgressAdd,
    ProjectProgressEdit,
  },
  props: {
    campaign_no_: String,
    customer_no_: String,
  },
  data: () => ({
    loading: null,
    search: "",
    datatable: {
      loading: null,
      loadingText: "Loading... Please wait...",
      columns: [
        { text: "Option", value: "id" },
        { text: "Title", value: "title" },
        { text: "Body", value: "body" },
        { text: "Created Date", value: "date_created" },
        { text: "Created By", value: "created_by" },
        { text: "Updated Date", value: "date_updated" },
        { text: "Updated By", value: "updated_by" },
        { text: "Published", value: "published" },
        // { text: "Option", value: null },
      ],
      data: [],
    },
    selectedId: null,
  }),
  methods: {
    async getData() {
      this.datatable.loading = true;
      var params = {};
      params = {
        campaign_no_: this.campaign_no_,
        customer_no_: this.customer_no_,
      };
      await this.$axios
        .get("project-progress/get-by-campaign", {
          params: params,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.progress_list;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.datatable.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    addNew() {
      this.$refs.formAdd.showDialog();
    },

    addCallback() {
      this.getData();
    },

    editData(item) {
      this.$refs.formEdit.getData(item.id);
      this.$refs.formEdit.showDialog();
    },

    editCallback() {
      this.getData();
    },


    browseFile2() {
      this.$refs.reffileupload2.click();
    },

    async doUpload2() {
      this.showLoadingOverlay(true);

      var fileupload = this.$refs.reffileupload2.files[0];

      // console.log(fileupload);

      var formData = new FormData();
      formData.append("campaign_no_", this.campaign_no_);
      formData.append("customer_no_", this.customer_no_);
      formData.append("fileupload", fileupload);
      formData.append("path", "campaign_progress");
      await this.$axios
        .post("upload/do-upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);

          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);

            this.formEdit.images.push(resData.data.file_src);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },



    async deleteData(id) {

      if (!confirm('Delete data?')) {
        return false;
      }


      this.showLoadingOverlay(true);
      let formData = new FormData();
      formData.append("id", id);
      await this.$axios
        .post("project-progress/delete", formData)
        .then((res) => {
          // console.log(res);
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.getData();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });

      this.showLoadingOverlay(false);
      this.dialogEdit = false;
    },

  },
  mounted() {
    this.getData();
  },
};
</script>

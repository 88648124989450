<template>
  <v-card :loading="loading" outlined>
    <v-toolbar dense elevation="0">
      <v-toolbar-title>Users</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="getData()">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </template>
        <span> Refresh </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="add()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span> Add </span>
      </v-tooltip>
    </v-toolbar>
    <v-divider></v-divider>

    <v-list>
      <v-list-item two-line v-for="(user, n) in users" :key="n">
        <v-list-item-content>
          <v-list-item-title>{{ user.username }}</v-list-item-title>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          <v-list-item-subtitle
            >Access Role as a
            <v-menu close-on-content-click>
              <template v-slot:activator="{ on, attrs }">
                <v-chip small v-bind="attrs" v-on="on" :color="(user.role=='manager') ? 'red' : 'blue'" dark>{{ user.role }} <v-icon small class="mx-0 px-0">mdi-chevron-down</v-icon></v-chip>
              </template>
              <v-list>
                <v-list-item v-for="role in portalRoles" :key="role" @click="changeRole(user.id, role)" selectable :value="role">
                  <v-list-item-title><span v-if="user.role!=role">{{ role }}</span><b v-else>{{ role }}</b></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-spacer></v-spacer>
        <v-btn icon @click="$refs.refUserDetailDialog.getDetail(user.username)"><v-icon>mdi-eye</v-icon></v-btn>
        <v-btn icon @click="del(user.id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>

    <v-dialog v-model="dialog" max-width="500px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card min-height="600" max-height="600" :loading="loadingAdd">
          <v-toolbar elevation="0" outlined>
            <v-toolbar-title>Add User</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="dialog = !dialog"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text class="mb-0 pb-0">
            <v-select :items="portalRoles" v-model="selectedRole" label="Role" hide-details></v-select>
            <v-text-field class="mb-0 pb-0" v-model="search" label="Search User" @keyup="findUser" hide-details></v-text-field>
          </v-card-text>
          <v-card-text class="px-0" style="max-height: 400px; overflow-y: auto">
            <v-alert color="warning" dark class="mx-4" v-if="search == ''">please type in the search box</v-alert>
            <v-alert color="warning" dark class="mx-4" v-if="search != '' && foundUsers.length == 0 && loadingSearch == false">User not found</v-alert>
            <v-skeleton-loader v-if="loadingSearch" boilerplate elevation="0" class="mb-2" :loading="loadingSearch" type="list-item-two-line"></v-skeleton-loader>
            <v-skeleton-loader v-if="loadingSearch" boilerplate elevation="0" class="mb-2" :loading="loadingSearch" type="list-item-two-line"></v-skeleton-loader>
            <v-skeleton-loader v-if="loadingSearch" boilerplate elevation="0" class="mb-2" :loading="loadingSearch" type="list-item-two-line"></v-skeleton-loader>
            <v-list>
              <v-list-item-group v-model="selectedUser" color="primary">
                <v-list-item two-line v-for="(user, n) in foundUsers" :key="n">
                  <v-list-item-content>
                    <v-list-item-title>{{ user.username }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-spacer></v-spacer>
                  <v-icon v-if="selectedUser == n">mdi-check</v-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-card-actions style="bottom: 0px; position: absolute; display: flex; width: 100%">
            <v-spacer></v-spacer>
            <v-btn @click.stop="dialog = !dialog">Cancel</v-btn>
            <v-btn color="primary" @click="save" :loading="loadingAdd">Sumbit</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <UserDetailDialog ref="refUserDetailDialog"></UserDetailDialog>
  </v-card>
</template>

<script>
import UserDetailDialog from "@/components/user/UserDetailDialog.vue";

export default {
  components: {
    UserDetailDialog,
  },
  props: {
    no_: String,
    customer_no_: String,
  },
  data: () => ({
    loading: false,
    loadingSearch: false,
    loadingAdd: false,
    valid: null,
    dialog: false,
    id: 0,
    campaign_no_: null,
    search: "",
    users: [],
    foundUsers: [],
    selectedUser: {},
    selectedRole: null,
    portalRoles: ["manager", "finance-accounting", "purchasing", "warehouse"],
  }),
  methods: {
    getData() {
      this.loading = true;
      this.campaign_no_ = this.no_;

      var params = {
        campaign_no_: this.campaign_no_,
        customer_no_: this.customer_no_,
      };
      this.$axios
        .get("campaign/data-users", {
          params: params,
        })
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.users = resData.data.users;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    findUser() {
      this.foundUsers = [];
      this.loadingSearch = true;
      this.campaign_no_ = this.no_;

      if (this.search == "") {
        this.loadingSearch = false;
        return;
      }

      var params = {
        search: this.search,
      };
      this.$axios
        .get("campaign/find-user", {
          params: params,
        })
        .then((res) => {
          this.loadingSearch = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.foundUsers = resData.data.users;
          } else {
            console.log(resData.status);
            console.log(resData.message);
          }
        })
        .catch((error) => {
          this.loadingSearch = false;
          this.axiosErrorHandler(error);
        });
    },
    add() {
      this.id = null;
      this.dialog = true;
      this.search = "";
      this.foundUsers = [];
    },
    save() {
      // this.file_src = this.$refs.reffileupload.users[0];

      this.loadingAdd = true;

      if (this.selectedUser == null) {
        this.showAlert("error", "Select user");
      }

      let formData = new FormData();
      formData.append("user_id", this.foundUsers[this.selectedUser].id);
      formData.append("role", this.selectedRole);
      formData.append("campaign_no_", this.campaign_no_);
      formData.append("customer_no_", this.customer_no_);

      this.$axios
        .post("campaign/save-user", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loadingAdd = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.dialog = false;
            this.getData();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loadingAdd = false;
          this.axiosErrorHandler(error);
        });
    },
    del(id) {
      if (confirm("delete?") == false) return;

      this.loading = true;

      let formData = new FormData();
      formData.append("id", id);

      this.$axios
        .post("campaign/delete-user", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.getData();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    changeRole(id, role) {
      this.loading = true;

      let formData = new FormData();
      formData.append("id", id);
      formData.append("role", role);

      this.$axios
        .post("campaign/change-user-role", formData)
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.getData();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<template>
  <div>
    <h3 class="mb-1">Summarize</h3>
    <p class="text--disabled">This is the summarize of the campaign. It shows the total amount of order, invoice, paid, and remaining amount. It also shows the total amount of delivery preparation, on delivery, receive, and returned.</p>
    <p>
      <v-btn icon @click="getData(true)" :loading="loading"><v-icon>mdi-sync</v-icon></v-btn>
    </p>
    <v-divider class="mt-2 mb-6"></v-divider>
    <v-row class="mb-3">
      <v-col lg="3">
        <v-card elevation="2" color="grey" dark>
          <v-card-title>Order Amount</v-card-title>
          <v-card-subtitle>Order Confirmation</v-card-subtitle>
          <v-card-text class="text-h4 text-right">{{ numberFormat(summarizeData.order_confirmation_incl_vat, 2) }}</v-card-text>
        </v-card>
      </v-col>
      <v-col lg="3">
        <v-card elevation="2" color="orange" dark>
          <v-card-title>Invoice Amount</v-card-title>
          <v-card-subtitle>Total Invoicing</v-card-subtitle>
          <v-card-text class="text-h4 text-right">{{ numberFormat(summarizeData.invoiced_incl_vat, 2) }}</v-card-text>
        </v-card>
      </v-col>
      <v-col lg="3">
        <v-card elevation="2" color="green" dark>
          <v-card-title>Paid Amount</v-card-title>
          <v-card-subtitle>&nbsp;</v-card-subtitle>
          <v-card-text class="text-h4 text-right">{{ numberFormat(summarizeData.invoice_paid_incl_vat, 2) }}</v-card-text>
        </v-card>
      </v-col>
      <v-col lg="3">
        <v-card elevation="2" color="red" dark>
          <v-card-title>Remaining Amount</v-card-title>
          <v-card-subtitle>&nbsp;</v-card-subtitle>
          <v-card-text class="text-h4 text-right">{{ numberFormat(summarizeData.remainingAmount, 2) }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col lg="3">
        <v-card elevation="2" color="info" dark>
          <v-card-title>Delivery Preparation</v-card-title>
          <v-card-subtitle>&nbsp;</v-card-subtitle>
          <v-card-text class="text-h4 text-right">{{ numberFormat(summarizeData.delivery_preparation, 2) }}</v-card-text>
        </v-card>
      </v-col>
      <v-col lg="3">
        <v-card elevation="2" color="info" dark>
          <v-card-title>On Delivery</v-card-title>
          <v-card-subtitle>&nbsp;</v-card-subtitle>
          <v-card-text class="text-h4 text-right">{{ numberFormat(summarizeData.on_delivery, 2) }}</v-card-text>
        </v-card>
      </v-col>
      <v-col lg="3">
        <v-card elevation="2" color="info" dark>
          <v-card-title>Received by Customer</v-card-title>
          <v-card-subtitle>&nbsp;</v-card-subtitle>
          <v-card-text class="text-h4 text-right">{{ numberFormat(summarizeData.received_by_customer, 2) }}</v-card-text>
        </v-card>
      </v-col>
      <v-col lg="3">
        <v-card elevation="2" color="info" dark>
          <v-card-title>Returned by Customer</v-card-title>
          <v-card-subtitle>&nbsp;</v-card-subtitle>
          <v-card-text class="text-h4 text-right">{{ numberFormat(summarizeData.returned_by_customer, 2) }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    campaign_no_: {
      type: String,
      required: true,
    },
    customer_no_: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: null,
    summarizeData: {
      order_confirmation_incl_vat: 0,
      invoiced_incl_vat: 0,
      delivery_preparation: 0,
      on_delivery: 0,
      received_by_customer: 0,
      returned_by_customer: 0,
      invoice_paid_incl_vat: 0,
      remainingAmount: 0,
    },
  }),
  methods: {
    async getData(refresh = false) {
      const uri = "campaign/detail/summarize";
      if (refresh == true) this.AxiosStorageRemove("GET", uri);

      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      var config = {
        params: {
          campaign_no_: this.campaign_no_,
          customer_no_: this.customer_no_,
        },
      };
      await this.$axios
        .get(uri, config)
        .then((res) => {
          this.summarizeData = res.data.data;

          this.showLoadingOverlay(false);

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 404) {
              this.notFound = true;
            }
          }
          this.showLoadingOverlay(false);
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<template>
  <v-card :loading="loading" outlined>
    <v-toolbar dense elevation="0">
      <v-toolbar-title>Images</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="getData()">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </template>
        <span> Refresh </span>
      </v-tooltip>
      <CampaignImageAdd
        :campaign_no_="campaign_no_"
        :customer_no_="customer_no_"
        @callback="getData()"
      ></CampaignImageAdd>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col class="d-flex flex-row">
          <div v-for="(image, n) in images" :key="n" class="mr-1">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                width="180"
                style="cursor: pointer"
              >
                <v-img
                  :src="image.img_src"
                  :lazy-src="image.img_src"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  cover
                  max-height="180"
                  max-width="200"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>

                  <v-divider v-if="hover" class="white--text"></v-divider>
                  <v-card-text
                    v-if="hover"
                    class="fill-height d-flex flex-column"
                    style="
                      font-size: 14px;
                      line-height: 14px;
                      background-color: rgba(0, 0, 0, 0.36);
                    "
                  >
                    <div
                      style=""
                      class="white--text"
                      @click.stop="$refs.refShowImage.showDialog(image.img_src)"
                    >
                      <span>{{ image.img_caption }}</span>
                    </div>
                    <div class="d-flex flex-row fill-height align-end">
                      <v-btn
                        icon
                        @click="$refs.refEdit.showDialog(image)"
                        class="white--text"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        large
                        color=""
                        class="white--text"
                        icon
                        @click.stop="del(image.id)"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </div>
                  </v-card-text>

                  <v-card-text v-if="hover"> </v-card-text>
                </v-img>
              </v-card>
            </v-hover>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <ShowImageDialog ref="refShowImage"></ShowImageDialog>
    <CampaignImageEdit
      ref="refEdit"
      :dataImage="selected_image"
      @callback="getData()"
    ></CampaignImageEdit>
  </v-card>
</template>

<script>
import ShowImageDialog from "@/components/ShowImageDialog.vue";
import CampaignImageAdd from "./CampaignImageAdd.vue";
import CampaignImageEdit from "./CampaignImageEdit.vue";

export default {
  components: {
    ShowImageDialog,
    CampaignImageAdd,
    CampaignImageEdit,
  },
  props: {
    no_: String,
    customer_no_: String,
  },
  data: () => ({
    loading: false,
    valid: null,
    valid2: null,
    images: [],
    campaign_no_: null,
    selected_image: {},
  }),
  methods: {
    getData() {
      this.images = [];
      this.loading = true;
      this.campaign_no_ = this.no_;
      var params = {
        campaign_no_: this.campaign_no_,
        customer_no_: this.customer_no_,
      };
      this.$axios
        .get("campaign/data-images", {
          params: params,
          cacheConfig: false,
        })
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.images = resData.data.images;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.$awn.alert("Internal Server Error");
            console.log(error.response.headers);
          } else {
            console.log("Error", error.message);
          }
        });
    },
    del(id) {
      if (confirm("delete?") == false) return;
      this.showLoadingOverlay(true);
      let formData = new FormData();
      formData.append("id", id);
      this.$axios
        .post("campaign/delete-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.getData();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    edit(image) {
      this.selected_image = image;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<template>
  <div>
    <page-toolbar :title="'Campaign Detail'"> </page-toolbar>
    <v-container>
      <page-title :title="'Campaign Detail'">
        Campaign Detail <span v-if="!loading">({{ $route.query.no_ }})</span>
        <template slot="action">
          <v-btn class="ml-n1 mx-1" @click="$router.go(-1)">Back</v-btn>
        </template>
      </page-title>
      <v-row>
        <v-col>
          <card-expansion title="General" :isMinimize="false">
            <v-row>
              <v-col md="4">
                <app-data-line label="Campaign No." :value="campaign.no_"></app-data-line>
                <app-data-line label="Description" :value="campaign.description"></app-data-line>
                <app-data-line label="Area Code" :value="campaign.global_dimension_1_code"></app-data-line>
                <app-data-line label="City" :value="campaign.city_code"></app-data-line>
                <app-data-line label="Status" :value="campaign.status"></app-data-line>
                <app-data-line label="Status Code" :value="campaign.status_code"></app-data-line>
                <app-data-line label="Sub Status OM" :value="campaign.substatus_om"></app-data-line>
              </v-col>
              <v-col md="4">
                <app-data-line label="Customer No." :value="customer.no_"></app-data-line>
                <app-data-line label="Cust. Name" :value="customer.name"></app-data-line>
                <app-data-line label="Cust. Address" :value="customer.address"></app-data-line>
                <app-data-line label="Cust. City" :value="customer.city"></app-data-line>
              </v-col>
              <v-col md="4">
                <app-data-line label="Starting Date" :value="appDateFormat(campaign.starting_date)"></app-data-line>
                <app-data-line label="Ending Date" :value="appDateFormat(campaign.ending_date)"></app-data-line>
                <app-data-line label="Last Modified Date" :value="appDatetimeFormat(campaign.last_date_modified)"></app-data-line>
                <app-data-line label="Last Sync Date" :value="appDatetimeFormat(campaign.last_sync_date)"></app-data-line>
              </v-col>
            </v-row>
          </card-expansion>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <card-expansion title="Update" :isMinimize="false" hideTitle :no-padding="true">
            <template #title>
                <v-tabs v-model="tabUpdate">
                  <v-tab>Summarize</v-tab>
                  <v-tab>Images</v-tab>
                  <v-tab>Attachments</v-tab>
                  <v-tab>Users</v-tab>
                  <v-tab>Contacts</v-tab>
                  <v-tab>Location</v-tab>
                  <v-tab>Progress</v-tab>
                  <v-tab>Document Order</v-tab>
                </v-tabs>
            </template>
            <v-row>
              <v-col>
                <v-tabs-items v-model="tabUpdate" class="pa-4">
                  <v-tab-item>
                    <Summarize
                      :campaign_no_="$route.query.no_"
                      :customer_no_="$route.query.customer_no_"
                    ></Summarize>
                  </v-tab-item>
                  <v-tab-item>
                    <CampaignImages
                      ref="refCampaignImages"
                      class="mb-4"
                      :no_="$route.query.no_"
                      :customer_no_="$route.query.customer_no_"
                    ></CampaignImages>
                  </v-tab-item>
                  <v-tab-item>
                    <CampaignAttachments
                      ref="refCampaignAttachments"
                      class="mb-4"
                      :no_="$route.query.no_"
                      :customer_no_="$route.query.customer_no_"
                    ></CampaignAttachments>
                  </v-tab-item>
                  <v-tab-item>
                    <CampaignUsers
                      ref="refCampaignUsers"
                      class="mb-4"
                      :no_="$route.query.no_"
                      :customer_no_="$route.query.customer_no_"
                    ></CampaignUsers>
                  </v-tab-item>
                  <v-tab-item>
                    <CampaignContact
                      ref="refCampaignUsers"
                      class="mb-4"
                      :no_="$route.query.no_"
                      :customer_no_="$route.query.customer_no_"
                    ></CampaignContact>
                  </v-tab-item>
                  <v-tab-item>
                    <CampaignLocation
                      ref="refCampaignUsers"
                      class="mb-4"
                      :no_="$route.query.no_"
                      :customer_no_="$route.query.customer_no_"
                    ></CampaignLocation>
                  </v-tab-item>
                  <v-tab-item>
                    <ProjectProgress
                      :campaign_no_="$route.query.no_"
                      :customer_no_="$route.query.customer_no_"
                    ></ProjectProgress>
                  </v-tab-item>
                  <v-tab-item>
                    <DocumentOrder
                      :campaign_no_="$route.query.no_"
                      :customer_no_="$route.query.customer_no_"
                    ></DocumentOrder>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </card-expansion>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CampaignImages from "./components/CampaignImages.vue";
import CampaignAttachments from "./components/CampaignAttachments.vue";
import CampaignUsers from "./components/CampaignUsers.vue";
import CampaignContact from "./components/CampaignContact.vue";
import CampaignLocation from "./components/CampaignLocation.vue";
import ProjectProgress from "./components/ProjectProgress.vue";
import DocumentOrder from "./components/DocumentOrder.vue";

import Summarize from "./components/Summarize.vue";
// import OrderConfirmation from "./components/OrderConfirmation.vue";
// import Invoicing from "./components/Invoicing.vue";
// import Delivery from "./components/Delivery.vue";
// import Payment from "./components/Payment.vue";

export default {
  components: {
    CampaignImages,
    CampaignAttachments,
    CampaignUsers,
    CampaignContact,
    CampaignLocation,
    ProjectProgress,
    DocumentOrder,
    Summarize,
    // OrderConfirmation,
    // Invoicing,
    // Delivery,
    // Payment,
  },

  data() {
    return {
      loading: false,
      breadcrumbItems: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/campaign",
          to: { name: "Campaign" },
        },
        {
          text: "Detail",
          disabled: true,
          to: { name: "Campaign" },
        },
      ],
      tabUpdate: null,
      campaign: {},
      images: [],
      customer: {},
      tabSummarize: null,
    };
  },

  methods: {
    getData(refresh = false) {
      if (refresh) this.AxiosStorageRemove("GET", "campaign/data-detail");
      this.loading = true;
      var params = {
        no_: this.$route.query.no_,
        customer_no_: this.$route.query.customer_no_,
      };
      this.$axios
        .get("campaign/data-detail", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.campaign = resData.data.campaign;
            this.images = resData.data.images;
            this.customer = resData.data.customer;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
  },

  computed: {
    atest() {
      console.log(this.selectedCutomer.no_);
      return "";
    },
  },

  mounted() {
    this.requiredLogin();
    this.getData(true);
  },
};
</script>
